/**
 * Extract query params as object from url string.
 * @param search History location search
 * @param allowedKeys List with URL params which are allowed and get returned
 * @returns URL search params object
 */
export const getURLSearchParamsByLocationSearch = <T>(
  search: string,
  allowedKeys?: readonly string[] | string[]
): T => {
  const urlSearchParams = new URLSearchParams(search)
  const allEntries: Record<string, string> = {}
  urlSearchParams.forEach((value, key) => {
    allEntries[key] = value
  })

  if (!allowedKeys || allowedKeys.length === 0) {
    return allEntries as unknown as T
  }

  const filteredEntries = Object.fromEntries(
    Object.entries(allEntries).filter(([key]) => allowedKeys.includes(key as string))
  )

  return filteredEntries as unknown as T
}
